import React, { useEffect, useContext } from "react";
import { Layout, SEO } from "../components";
import {
  Intro,
  Features,
  HowItWorks,
  TryOurApp,
  About,
  Technology,
  TeamBehindProject,
  Benefits
} from "../sections";
import { injectIntl } from "gatsby-plugin-intl";
import * as Scroll from "react-scroll";
import MenuContext from "../components/context/";
import { useIntl } from "gatsby-plugin-intl";

const IndexPage = () => {
  const intl = useIntl();
  const t = x => {
    return intl.formatMessage({ id: x });
  };

  const Context = useContext(MenuContext);
  const scroller = Scroll.scroller;
  const { activeMenu } = Context;

  useEffect(() => {
    if (activeMenu) {
      if (typeof window != "undefined") {
        scroller.scrollTo(activeMenu, {
          spy: true,
          smooth: true,
          duration: 600,
          offset: -50
        });
      }
      Context.changeActiveMenu("");
    }
  }, [activeMenu, scroller, Context]);

  return (
    <Layout>
      <SEO title="" description={t("home_seo_description")} />
      <Intro />
      <Scroll.Element name="product">
        <div>
          <Benefits />
          <HowItWorks />
          <Features />
          <TryOurApp />
        </div>
      </Scroll.Element>
      <Scroll.Element name="about">
        <TeamBehindProject />
        <Technology />
        <About />
      </Scroll.Element>
    </Layout>
  );
};

export default injectIntl(IndexPage);
